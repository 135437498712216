import store from "@/store";

const defaultGoodsTitleList = [
  {
    title: "车型",
    active: false
  },
  {
    title: "适用车型",
    active: false
  },
  {
    title: "配件名称",
    active: true
  },
  {
    title: "配件编码",
    active: true
  },
  {
    title: "生产码",
    active: true
  },
  {
    title: "配件分类",
    active: true
  },
  {
    title: "品牌",
    active: true
  },
  {
    title: "产地",
    active: true
  },
  {
    title: "配件性质",
    active: true
  },
  {
    title: "生产批号",
    active: true
  },
  {
    title: "品质",
    active: true
  },
  {
    title: "规格",
    active: true
  }
];
const partsInfo = {
  配件名称: "name",
  配件编码: "code",
  生产码: "productionCode",
  配件分类: "category",
  品牌: "brand",
  产地: "productionPlace",
  配件性质: "property",
  生产批号: "productionBatch",
  品质: "insurCertType",
  规格: "model",
  车型: "vehModel",
  适用车型: "generalVehModels"
};
/**
 * 获取当前商品展示名称
 */
export const getGoodsNameText = function(goods) {
  const companyInfo = store.state.companyInfo.company;
  const goodsTitleList = companyInfo.setting.goodsSetting.goodsTitleList || defaultGoodsTitleList;
  const rule = goodsTitleList.filter(item => item.active);
  let name = [];
  if (rule && rule.length) {
    rule.map(item => {
      const field = partsInfo[item.title];
      if (goods[field]) {
        if (field === "name") {
          name.push(`【${String(goods[field])}】`);
        } else {
          name.push(String(goods[field]));
        }
      }
    });

    return name.join(" ");
  }
};
